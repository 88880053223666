import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import BasicTable from "components/basicTable";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";
import BuildGif from "./../images/programming/build.gif";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "programming-kids"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/programming-kids.png"}) { ...eyecatchImg },
    fizzbuzz_hq: file(relativePath: { eq: "programming/fizzbuzz_hq.png"}) { ...normalImgWithHQ },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    cssd_size: file(relativePath: { eq: "cover/pcparts/ssd_size.PNG"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`子供のプログラミング学習に最適なパソコンは何か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="子どものプログラミングに最適なパソコン" mdxType="Image" />
    <p>{`小学生でプログラミングが必修化になったことやオンライン授業の普及とともに子供にパソコンを買い与える家庭が増えてきている。`}</p>
    <p>{`プログラミングと言っても、理数系の大学で使用するC言語やWebページを作成するためのJavaScriptなどのプログラミング言語を学習するわけではなく、プログラミングの思考を学べる教材を使用して算数や理科などの授業に補助的に取り入れる程度ではある。`}</p>
    <p>{`ともあれ、これを元にプログラミングに興味を持った子供に対し、学習用としてパソコンを購入することは悪いことではないだろう。Scratchやレゴプログラミングといった論理的思考力を培う疑似的なプログラミング教材もパソコンがあれば遊ぶことができる。`}</p>
    <p>{`そこで、どのようなパソコンを子供に買い与えたら良いのかについて、大手電子機器メーカーの子育て中エンジニアが解説する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "子供にとって何が必要な要素であるかをまず考える",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AD%90%E4%BE%9B%E3%81%AB%E3%81%A8%E3%81%A3%E3%81%A6%E4%BD%95%E3%81%8C%E5%BF%85%E8%A6%81%E3%81%AA%E8%A6%81%E7%B4%A0%E3%81%A7%E3%81%82%E3%82%8B%E3%81%8B%E3%82%92%E3%81%BE%E3%81%9A%E8%80%83%E3%81%88%E3%82%8B",
        "aria-label": "子供にとって何が必要な要素であるかをまず考える permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`子供にとって何が必要な要素であるかをまず考える`}</h2>
    <p>{`子供にパソコンを与え、プログラミングで遊んでもらうことにより、`}<em parentName="p">{`論理的な思考力や独創的な発想力を養ってもらうということが目的`}</em>{`となる。`}</p>
    <p>{`マウスやキーボードに慣れてもらい、インターネットを使うことで知識の幅も同時に広げていくという意図もあるかもしれない。`}</p>
    <p>{`上手くいけば問題ないが、パソコンを子供に与える事によるマイナス面も、子供が飽きてパソコンを触らなくなる、視力が低下する、教育に悪いサイトや掲示板、Youtube、ゲームなどにハマるなど様々な事が考えられる。`}</p>
    <p>{`このようなリスクを最小限に抑えつつ、本来の目的を達成するには、`}<em parentName="p">{`親の関与`}</em>{`、`}<em parentName="p">{`パソコンのハードウェアスペック`}</em>{`、`}<em parentName="p">{`パソコンのソフトウェア`}</em>{`の３つが重要となる。`}</p>
    <p>{`価格を抑えつつ目的達成の確度が高いパソコンの選び方を解説する。`}</p>
    <h2 {...{
      "id": "子供用パソコンに求められるスペック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AD%90%E4%BE%9B%E7%94%A8%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E6%B1%82%E3%82%81%E3%82%89%E3%82%8C%E3%82%8B%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF",
        "aria-label": "子供用パソコンに求められるスペック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`子供用パソコンに求められるスペック`}</h2>
    <p>{`それでは選び方を解説していく。まず大前提としてパソコンはCPU、メモリ、SSDなどのハードウェアモジュールとWindows、ChromeなどのOS、OS上で動くソフトウェアを組み合わせて成り立つものである。`}</p>
    <p>{`部品の良し悪しで性能と価格が決まるため、部品ごとに慎重に吟味していくことが肝心となる。`}</p>
    <p>{`ここではプログラミングを学習することを前提として、おすすめのスペックを理由つきで紹介していく。`}</p>
    <h3 {...{
      "id": "デスクトップよりノートパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%82%88%E3%82%8A%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "デスクトップよりノートパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`デスクトップよりノートパソコン`}</h3>
    <p>{`親が関与しやすいという観点で、デスクトップよりもノートパソコンの方が優れている。`}</p>
    <p>{`デスクトップだと子供の成果物を見せて欲しい時であっても子供の部屋に行かなければならないが、ノートパソコンだと持ってきてもらうことができし、ソファーに座って一緒に見ることも可能である。`}</p>
    <p>{`移動制約によるストレスを緩和できるという観点でノートパソコンの方がありがたいだろう。`}</p>
    <h3 {...{
      "id": "画面サイズは156インチのノングレア液晶",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%94%BB%E9%9D%A2%E3%82%B5%E3%82%A4%E3%82%BA%E3%81%AF156%E3%82%A4%E3%83%B3%E3%83%81%E3%81%AE%E3%83%8E%E3%83%B3%E3%82%B0%E3%83%AC%E3%82%A2%E6%B6%B2%E6%99%B6",
        "aria-label": "画面サイズは156インチのノングレア液晶 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`画面サイズは15.6インチのノングレア液晶`}</h3>
    <Image {...props} name="fizzbuzz_hq" alt="Scratchの画面" mdxType="Image" />
    <p>{`上記はマサチューセッツ工科大学で開発された子供に大人気のプログラミング教材`}<a parentName="p" {...{
        "href": "https://scratch.mit.edu/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Scratch`}</a>{`の開発画面である。FizzBuzzという３の倍数でFizz、5の倍数でBuzz、15の倍数でFizzBuzzと呼ぶコードを書いて実行している。`}</p>
    <p>{`見ると多くの画面に分かれており、それなりに表示領域を確保しなければならないことが分かる。`}</p>
    <p>{`このような単純なコードだと、まだモバイルノートでも大丈夫かもしれないが、プログラミングが入り組んでくるとより大きな表示領域が欲しくなってくるだろう。`}</p>
    <p>{`子供に小さい文字をあまり読ませるのも視力の悪化が心配であるし、Scratch以外にも子供用のプログラミング教材はビジュアルに訴えるものが多いため、`}<strong parentName="p"><em parentName="strong">{`15.6インチの画面サイズは欲しいところ`}</em></strong>{`である。`}</p>
    <p><em parentName="p">{`13.3インチは小さすぎる`}</em>{`ので、持ち運びが容易だからと言って安易に選ぶべきではないと注意しておこう。`}</p>
    <p>{`また、`}<strong parentName="p"><em parentName="strong">{`光の反射が少ないノングレア液晶も目の保護という観点では重要`}</em></strong>{`である。長時間行った時に目の疲れが少なく、睡眠障害に陥る可能性などを低減させることができるはずである。`}</p>
    <p>{`大手メーカー製は光沢のある液晶をノートパソコンに採用している比率が非常に高いので注意したい。理由は主に量販店での見栄えがするためである。`}</p>
    <h3 {...{
      "id": "CPU-PassMark-6500点以上、メモリ8GBで快適動作",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU-PassMark-6500%E7%82%B9%E4%BB%A5%E4%B8%8A%E3%80%81%E3%83%A1%E3%83%A2%E3%83%AA8GB%E3%81%A7%E5%BF%AB%E9%81%A9%E5%8B%95%E4%BD%9C",
        "aria-label": "CPU PassMark 6500点以上、メモリ8GBで快適動作 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPU PassMark 6500点以上、メモリ8GBで快適動作`}</h3>
    <p>{`ギガスクール構想で採択されているノートパソコンの基準は確かメモリ4GBで、かなりローエンドなCPUが搭載されて`}<em parentName="p">{`不安しか無いスペック`}</em>{`となっている。`}</p>
    <p>{`もっさりとした動きだと、その分時間の無駄であるばかりか、ストレスを感じさせることになり、子供がせっかく購入したパソコンを開かなくなる可能性が増大する。`}</p>
    <p>{`このようなケースを避けるために`}<strong parentName="p"><em parentName="strong">{`CPUの総合的な性能を示すPassMarkで最低でも6500点は取れるようなパソコンかつ8GBメモリ`}</em></strong>{`は備えておこう。`}</p>
    <p>{`これは事務作業を行う時にストレスなく作業ができる程度のスペックである。子供用のプログラミング教材は軽いものが多いため、事務が快適にできるパソコンであれば良い。`}</p>
    <p>{`CPU性能比較表でPassMarkスコアを購入時には必ずチェックしておこう。`}</p>
    <Card {...props} title="CPU性能比較表" description="PASSMARK6500点以上を一つの基準としてパソコンを探していこう。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <h3 {...{
      "id": "SSD-256GB搭載",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD-256GB%E6%90%AD%E8%BC%89",
        "aria-label": "SSD 256GB搭載 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSD 256GB搭載`}</h3>
    <p>{`パソコンのストレージ容量は128GBで良いかもしれないが、Windows Updateの度に容量を圧迫するなど、不安定な要素もあるので、256GBあれば子供用としては安心だと思われる。`}</p>
    <p>{`256GBは多くのパソコンで標準的に搭載されている量なので、あまり128GB SSDのパソコンは安物以外見ない気もするが注意したい。容量の決め方はより詳細には下記で説明しているので参考にして欲しい。`}</p>
    <Card {...props} title="SSDの容量のおすすめは何か？" description="128GB、256GB、512GBあたりでどの容量を選択すべきかについて考えを述べます。" image="cssd_size" path="/ssd/" mdxType="Card" />
    <h3 {...{
      "id": "ウイルスバスターを搭載して子供を有害なサイトから守る",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%83%90%E3%82%B9%E3%82%BF%E3%83%BC%E3%82%92%E6%90%AD%E8%BC%89%E3%81%97%E3%81%A6%E5%AD%90%E4%BE%9B%E3%82%92%E6%9C%89%E5%AE%B3%E3%81%AA%E3%82%B5%E3%82%A4%E3%83%88%E3%81%8B%E3%82%89%E5%AE%88%E3%82%8B",
        "aria-label": "ウイルスバスターを搭載して子供を有害なサイトから守る permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルスバスターを搭載して子供を有害なサイトから守る`}</h3>
    <p><em parentName="p">{`ペアレンタルコントロール`}</em>{`という親が子供のパソコン操作に制限を加えられる機能がある。`}</p>
    <p>{`Windowsの標準機能としても提供されているものだが、せいぜいがアダルトサイトのフィルタリングと見せたくないサイトのブラックリスト登録程度であり、より細かい処理を行う事ができない。`}</p>
    <p><a parentName="p" {...{
        "href": "https://docs.trendmicro.com/TE60/JA-JP/005006/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`トレンドマイクロのペアレンタルコントロール`}</a>{`のならばアダルトのみならず、酒、たばこ、出会い系、暴力、差別など多くのカテゴリをカスタマイズして排除することができる。`}</p>
    <p>{`トレンドマイクロは日本の上場会社であり、特に日本でのシェアは圧倒的な事から、日本語に対するカテゴリ分けのレベルは最も高く、ブラックリスト登録無でも高いプロテクト力が期待できる。`}</p>
    <p>{`トレンドマイクロの`}<a parentName="p" {...{
        "href": "https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=517737.10000611&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ウイルスバスタークラウド`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=517737.10000611&type=3&subid=0",
        "alt": "''"
      }}></img>{`
を導入しておけば一先ず余計な心配はせずに済むだろう。`}</p>
    <p>{`ウイルス対策ソフトは無料で３か月ぐらいは付いてくるので、それで様子を見ても良いが、期間が終わればアンインストールしてウイルスバスターを入れることをおすすめする。`}</p>
    <h3 {...{
      "id": "その他-Q-and-A",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96-Q-and-A",
        "aria-label": "その他 Q and A permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他 Q and A`}</h3>
    <p>{`持論になるが良く聞かれる質問について答えていく。`}</p>
    <p>{`Q: OSは何が良いか？`}<br />{`
A: Windowsが良い、パソコンのシェアが高く、大学生、社会人になってからも役に立つ事が期待できるからである。`}</p>
    <p>{`Q: DVDドライブは必要か？`}<br />{`
A: 不要。むしろDVDドライブがノートパソコンに内蔵されていると本体が分厚くなってしまいタイピングが行いにくい。積極的に削りたい。`}</p>
    <p>{`Q: グラフィックボードは必要か？`}<br />{`
A: 不要。子供用のプログラミング教材でグラフィックパワーを必要とするものはまずないだろう。`}</p>
    <p>{`Q: WordやExcelは必要`}<br />{`
A: 不要。大学生では使う機会があるので、こどもが中学生高学年ぐらいだったら買っても良いかもぐらい。`}</p>
    <h3 {...{
      "id": "子供用のパソコンに必要なスペックまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AD%90%E4%BE%9B%E7%94%A8%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "子供用のパソコンに必要なスペックまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`子供用のパソコンに必要なスペックまとめ`}</h3>
    <p>{`以上をまとめると下記が推奨のスペックとなる。これを基準にしてパソコンを探せばよいだろう。`}</p>
    <AccentBox title="子供用のパソコンに必要なスペック" mdxType="AccentBox">
  <li><span className="bold">サイズ</span>・・・15.6インチ程度</li>
  <li><span className="bold">CPU</span>・・・PassMark6500以上。<Link to="/cpu/" mdxType="Link">CPU比較表参照</Link></li>
  <li><span className="bold">メモリ</span>・・・8GB</li>
  <li><span className="bold">ストレージ</span>・・・256GB</li>
  <li><span className="bold">OS</span>・・・Windows11 Home Edition</li>
  <li><span className="bold">ウイルス対策ソフト</span>・・・<a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=517737.10000011&type=3&subid=0" target="_blank" rel="nofollow noopener">ウイルスバスター クラウド</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=517737.10000011&type=3&subid=0" />を導入する。取り敢えずはパソコン付属のウイルス対策ソフトで様子を見るのもあり。</li>
    </AccentBox>
    <h2 {...{
      "id": "子供用パソコンとしておすすめの機種２選",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AD%90%E4%BE%9B%E7%94%A8%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%A8%E3%81%97%E3%81%A6%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E6%A9%9F%E7%A8%AE%EF%BC%92%E9%81%B8",
        "aria-label": "子供用パソコンとしておすすめの機種２選 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`子供用パソコンとしておすすめの機種２選`}</h2>
    <p>{`それでは最後に私のおすすめパソコンを紹介しておこう。ウイルス対策ソフトは自分で買っていただくとして、それ以外は全て上記で述べたハードウェアスペック要件を満たしたものとなっている。`}</p>
    <h3 {...{
      "id": "mouse-X5-R5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mouse-X5-R5",
        "aria-label": "mouse X5 R5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`mouse X5-R5`}</h3>
    <p>{`15.6インチのノングレア液晶を採用したマウスコンピュータのエントリーモデルパソコン。`}</p>
    <p>{`15.6インチは2kg程度の重量が標準的であるが、このモデルは低消費電力のCPUを採用した軽量・スリムボディを持ち`}<em parentName="p">{`重量はわずか1.43kg`}</em>{`。`}</p>
    <p>{`こどもでも楽々持ち運べてかつ、薄型のためタイピングも行いやすい。`}</p>
    <p>{`値段も税込み・送料込みで11万円程度とコスパも高いので文句なしでおすすめできる逸品である。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fg%2Fgmouse-x5-r5-h%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt="" />mouse X5-R5</a>
    </SmallBox>
    <h3 {...{
      "id": "LENOVO-IdeaPad-Slim-550-156型",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#LENOVO-IdeaPad-Slim-550-156%E5%9E%8B",
        "aria-label": "LENOVO IdeaPad Slim 550 156型 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LENOVO IdeaPad Slim 550 15.6型`}</h3>
    <p>{`コスパを最大限追求したいならばLenovoのIdea Padである。紹介しているマウスコンピュータほど軽量ではないものの、送料込みで6万円程度でスペックを満たすPCを購入できるのは魅力が高い。`}</p>
    <p>{`取り敢えず安くて品質の高いものということであればこの機種で間違えは無い。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887538317&vc_url=https%3A%2F%2Fwww.lenovo.com%2Fjp%2Fja%2Fjpad%2Fnotebooks%2Fideapad%2Fslim-5-series%2FIdeaPad-5-15ARE05%2Fp%2F88IPS501393%3Fcid%3Djp%3Aaffiliate%3Adsry7f" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887538317" height="1" width="0" border="0" alt="" />LENOVO IdeaPad Slim 550 15.6型</a>
    </SmallBox>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      